import React, {Suspense} from 'react'
import { Route, Redirect } from 'react-router-dom'
import auth from './auth';
import styles from '../Components/HOC/LoadingHOC.module.css';

const SimpleRoute = ({ component: Component, ...rest }) => {

  return(
    <Route
      {...rest}
      render={props =>
        !auth.tokenNotExpired() && auth ? (
          <React.Fragment>
          {
            auth.deauthenticateUser()
          }
          {
            <Suspense fallback={<div className={styles.loader} />}><Component {...props} /></Suspense>
          }
          </React.Fragment>
        ) : (
            
          <Redirect
            to={{
              pathname: "/user",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

  

export default SimpleRoute