import React, {Suspense} from 'react'
import { Route, Redirect } from 'react-router-dom'
import Auth from './auth';
import styles from '../Components/HOC/LoadingHOC.module.css';
import { toast } from 'react-toastify';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Auth.tokenExists() ? 
        Auth.tokenNotExpired() ? 
        (
          <React.Fragment>
            {
              <Suspense fallback={<div className={styles.loader} />}><Component {...props} /></Suspense>
            }
          </React.Fragment>
         
        ) : (
          <React.Fragment>
            {
              toast.dismiss()
            }
            {
              toast.warn(<div>Ο χρόνος σύνδεσής σας έχει λήξει. Επιστρέψατε στην αρχική σελίδα εισόδου προκειμένου να επανασυνδεθείτε</div>, {position: "top-center", autoClose: false} )
            }
            {
              Auth.deauthenticateUser()
            }
            {
              <Redirect
                to={{
                  pathname: '/login',
                  state: { 
                    from: props.location,
                    //message: 'Η σύνδεσή σας έχει λήξει.' //this.props.location.state.message 
                  }
                }}
              />
            }  
          </React.Fragment>

        ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />      
      )
    }
  />
)

export default PrivateRoute