import React from 'react';
import styles from './Footer.module.css';

const footer = () => {
    return (
        <nav className={`navbar is-fixed-bottom `} style={{position: 'relative'}}>
                <div className={`has-text-centered ${styles.footer}`}>
                    Copyright &copy; 2019 - {process.env.REACT_APP_AGE_COPYRIGHT} Γενικό Επιτελείο Εθνικής Άμυνας <br />
                    Ανάπτυξη: Γενικό Επιτελείο Αεροπορίας/Κέντρο Μηχανογράφησης <br />
                    Με την επιφύλαξη παντός δικαιώματος.
                </div>
        </nav>
    );
}

export default footer;