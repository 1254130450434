import axios from 'axios';
import Auth from './auth';


export function getServerTime(){
    return axios.get('/api/ServerTime', {
        headers: {
            Authorization: 'Bearer ' + Auth.getToken() //the token is a variable which holds the token
        }
    })
    .then(response => {
        return (response);
    })
    .catch(error => {
        if(error.response){
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //console.log(error.response.data);
            
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return ['Παρουσιάστηκε κάποιο πρόβλημα.', 'Παρακαλούμε δοκιμάστε αργότερα.']
        }else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request.data);
            return ['Παρουσιάστηκε κάποιο πρόβλημα.', 'Παρακαλούμε δοκιμάστε αργότερα.']
        }else {
            // Something happened in setting up the request that triggered an Error
            //console.log('Error', error.message);
            return ['Παρουσιάστηκε κάποιο πρόβλημα.', 'Παρακαλούμε δοκιμάστε αργότερα.']
        }
        //console.log(error.config);
    })
    
}