import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
require('dotenv').config()

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_BASEURL;

try {
    if (window.localStorage) {
        if (!!document.documentMode) {
            const element = <h1>Η εφαρμογή δεν υποστηρίζει καμμία έκδοση του Internet Exlorer.<br />Παρακαλώ δοκιμάστε κάποιον άλλο φυλλομετρητή.</h1>;
            ReactDOM.render(element, document.getElementById('root'));
        } else {
            ReactDOM.render(<BrowserRouter basename='/app'><App /></BrowserRouter>, document.getElementById('root'));
        }

    }
}
catch (err) {
    const element = <h1>Θα πρέπει να ενεργοποιήσετε τη χρήση cookies - Local Storage, προκειμένου να τρέξετε την εφαρμογή.</h1>;
    ReactDOM.render(element, document.getElementById('root'));
}



serviceWorker.unregister();
