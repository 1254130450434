import React, { Component } from 'react';
import Footer from './Components/Footer/Footer';
import {Switch} from 'react-router-dom';
//import LoginLayout from '../src/Layout/LoginLayout/LoginLayout';
//import ApplicationLayout from '../src/Layout/ApplicationLayout/ApplicationLayout';
import styles from './App.module.css';
import PrivateRoute from './Services/PrivateRoute';
import SimpleRoute from './Services/SimpleRoute';
import { ToastContainer } from 'react-toastify';

const LoginLayout = React.lazy(()=>import('../src/Layout/LoginLayout/LoginLayout'));
const ApplicationLayout = React.lazy(()=>import('../src/Layout/ApplicationLayout/ApplicationLayout'));

class App extends Component {

  render() {
    return (
      <section className={`hero is-fullheight ${styles.IsGrey}`}>
          <Switch>
            <PrivateRoute path="/user" component={ApplicationLayout} />
            <SimpleRoute path= "/" component= {LoginLayout}  />
          </Switch>
          <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
          />    

        <div className={"hero-footer"} style={{zIndex: 10}}>
          <Footer />
        </div>
      </section>
    );
  }
}

export default App;
