import React  from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getServerTime } from '../Services/ServerTime';

class auth {
  constructor() {
    this.checkExpirationDate = null;
    this.flag = false
  }

  static authenticateUser(data) {
    localStorage.setItem('token', data)
  }

  static deauthenticateUser() {
    auth.stopCheckingExpirationDate();
    localStorage.removeItem('token');
  }

  static getToken() {
    return localStorage.getItem('token')
  }

  static tokenExists(){
    var token = this.getToken();
    if(token!==null){
      return true;
    }else{
      return false;
    }
  }

  static getTokenInfo() {
    const token = this.getToken()
    if (token != null && !this.isTokenExpired(token)) {
      return this.decodeToken(token)
    } else {
      return { username: '' }
    }
  }

  static urlBase64Decode(str) {
    try{
      let output = str.replace(/-/g, '+').replace(/_/g, '/')
      switch (output.length % 4) {
        case 0: {
          break
        }
        case 2: {
          output += '=='
          break
        }
        case 3: {
          output += '='
          break
        }
        default: {
          throw new Error('Illegal base64url string!')
        }
      }
      return this.b64DecodeUnicode(output)
    }catch(err){
      return false
    }


  }

  static b64decode(str) {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
    let output = ''

    str = String(str).replace(/=+$/, '')

    if (str.length % 4 === 1) {
      throw new Error(
        "'atob' failed: The string to be decoded is not correctly encoded."
      )
    }

    for (
      let bc = 0, bs, buffer, idx = 0;
      (buffer = str.charAt(idx++));
      ~buffer &&
      ((bs = bc % 4 ? bs * 64 + buffer : buffer), // tslint:disable-line
      bc++ % 4)
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0 // tslint:disable-line
    ) {
      buffer = chars.indexOf(buffer)
    }
    return output
  }

  static b64DecodeUnicode(str) {
    return decodeURIComponent(
      Array.prototype.map
        .call(this.b64decode(str), c => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
  }

  static decodeToken(token) {
    try{
      const parts = token.split('.')

      if (parts.length !== 3) {
        //throw new Error('JWT must have 3 parts')
        auth.deauthenticateUser()
      }
  
      const decoded = this.urlBase64Decode(parts[1])
      if (!decoded) {
        //throw new Error('Cannot decode the token')
        auth.deauthenticateUser()
      }
  
      return JSON.parse(decoded)
    }catch(err){
      auth.deauthenticateUser();
    }

  }

  static getTokenIssuedDate(token){
    const decoded = this.decodeToken(token)

    if(typeof decoded !== 'undefined'){
      if (!decoded.hasOwnProperty('nbf')) {
        return null
      }
  
      const date = new Date(0)
      date.setUTCSeconds(decoded.nbf)
  
      return date
    }
  }

  static getTokenExpirationDate(token) {
    const decoded = this.decodeToken(token)

    if(typeof decoded !== 'undefined'){
      if (!decoded.hasOwnProperty('exp')) {
        return null
      }
  
      const date = new Date(0)
      date.setUTCSeconds(decoded.exp)
  
      return date
    }

  }

  static isTokenExpired(token, offsetSeconds) {
    const expirationDate = this.getTokenExpirationDate(token)
    offsetSeconds = offsetSeconds || 0

    if (expirationDate === null || typeof expirationDate === 'undefined' ) {
      return true
    }

    if(typeof new auth().timeDiff === 'undefined'){
      if(!this.flag){
        this.flag = true
        //console.log(this.flag)
        getServerTime()
        .then(response=>{
          if(response.status===200){
            auth.startCheckingExpirationDate(response.data);
            return false
          }else {
            return true
          }
        })
      }else{
        return false
      } 
    }else if(new auth().timeDiff > 0){
      return false
    }else{
      return true
    }
    //return new auth().timeDiff > 0

    //return !(expirationDate.valueOf() > new Date().valueOf() + offsetSeconds * 1000)
  }

  static tokenNotExpired() {
    const token = this.getToken()
    return token !== null && !this.isTokenExpired(token)
  }

  /*
  static getSecondsBeforeTokenExpiration(){
    const token = this.getToken();
    const expirationDate = this.getTokenExpirationDate(token)
    var differenceInSeconds = (expirationDate.getTime() - new auth().currentDateTime.getTime()) / 1000;

    return Math.round(Math.abs(differenceInSeconds))
  }
  */

  static startCheckingExpirationDate(date){
    try{
      //console.log('date: ' + date)
      var tsp = new Date(moment.utc(date).toDate());
      //var performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
      //tsp.setTime(tsp.getTime() + performance.timing.loadEventStart - performance.timing.navigationStart)
      //console.log('tcp: ' + tsp)
      var dateExpiration = new Date(auth.getTokenExpirationDate(auth.getToken()));
      var timeDiff = Math.ceil( (dateExpiration.getTime() - tsp.getTime()) / 1000 );
      auth.prototype.timeDiff = timeDiff;
  
      this.checkExpirationDate = setInterval(function() {
          tsp.setSeconds(tsp.getSeconds() + 1);
          //auth.prototype.currentDateTime = tsp;
          var dateExpiration = new Date(auth.getTokenExpirationDate(auth.getToken()));
          //console.log('dateExpiration:' + dateExpiration.getTime())
          //console.log('tsp-interval: ' + tsp.getTime())
          //console.log(dateExpiration)
          var timeDiff = Math.ceil( (dateExpiration.getTime() - tsp.getTime()) / 1000 );
          auth.prototype.timeDiff = timeDiff;
          //var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
          //console.log(timeDiff);

  
          if(timeDiff < 120 && timeDiff > 1){
            if (! toast.isActive(this.toastId)) {
              this.toastId = toast.warn(<div>Ο χρόνος σύνδεσής σας θα λήξει σε λιγότερο από δύο λεπτά. Παρακαλούμε αποθηκεύσετε ό,τι ενέργειες έχετε κάνει έως τώρα.</div>, {position: "top-center", autoClose: false, closeOnClick: false } );
            }
          }
          if(timeDiff < 1){
              auth.deauthenticateUser();
              toast.dismiss();
              toast.warn(<div>Ο χρόνος σύνδεσής σας έχει λήξει. Επιστρέψατε στην αρχική σελίδα εισόδου προκειμένου να επανασυνδεθείτε</div>, {position: "top-center", autoClose: false} )
              window.logoutUser.logoutUser();
              //window.location.href = '../login';
              //const root = document.getElementById('root');
              //return ReactDOM.render(<Switch><Router><Redirect to='/login'/></Router></Switch>, root);

          }
          //console.log(tsp.toString());
          
      }, 1000); // 
    }catch(err){
      //console.log(err)
    }

  }

  static getTimeDiff(){
    return new auth().timeDiff;
  }

  static stopCheckingExpirationDate(){
    clearInterval(this.checkExpirationDate);
  }

}

export default auth
